
import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import variables from "@/router/api";
import ResusableFunctions from "@/composables/ReusableFunctions";

export default defineComponent({
  name: "client-claims-table",
  components: {},
  props: {
    widgetClasses: String,
    claims: Array,
    type: String,
  },
  data() {
    return {
      link: "",
      role: "",
      underwriter: variables.UNDERWRITER_USER_ROLE,
    };
  },
  setup() {
    const {
      reinitialization,
      useReusableNavigation,
      displayDatatable,
    } = ResusableFunctions();

    onMounted(() => {
      displayDatatable(["basicExample"]);
    });

    return {
      useReusableNavigation,
      reinitialization,
    };
  },
  created() {
    const store = useStore();
    this.role = store.getters.currentUser.role;
  },
  methods: {
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
  },
});
